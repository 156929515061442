import { cloneElement } from 'react'

import classnames from 'classnames'

type Props = {
  type?: 'danger' | 'warning' | 'success' | 'info' | 'action'
  className?: string
  icon?: JSX.Element
  children: React.ReactNode
}

const typeToColorMap = {
  danger: 'text-red-900 bg-red-100',
  action: 'text-primary-900 bg-branding-light',
  warning: 'text-yellow-900 bg-yellow-100',
  success: 'text-green-900 bg-green-100',
  info: 'text-gray-900 bg-gray-100',
}

export default function Message({ children, className, icon, type = 'info', ...restProps }: Props) {
  const clonedIcon = icon && cloneElement(icon, { size: 20, className: 'mr-1.5 flex-shrink-0' })

  return (
    <div
      className={classnames(
        'flex items-center rounded-lg px-3 py-2 text-sm font-medium',
        className,
        typeToColorMap[type],
      )}
      {...restProps}
    >
      {clonedIcon}
      <span>{children}</span>
    </div>
  )
}
